$(document).ready(function () {
	var swiper = new Swiper('.home-testimonial .reviews.left', {
		spaceBetween: 15,
		loop: true,
		speed: 3000,
		slidesPerView: 3,
		autoplay: {
			delay: 0,
			pauseOnMouseEnter: true,
			disableOnInteraction: false,
		},
    breakpoints: {
      0: {
          slidesPerView: 1.8,
      },
      992: {
          slidesPerView: 2,
          spaceBetweenSlides: 40
      },
      1200: {
          slidesPerView: 3,
          spaceBetweenSlides: 40
      }
    }
	});
	var swiper = new Swiper('.home-testimonial .reviews.right', {
		spaceBetween: 15,
		loop: true,
		speed: 3000,
		slidesPerView: 3,
		autoplay: {
			delay: 0,
			pauseOnMouseEnter: true,
			disableOnInteraction: false,
			reverseDirection: true
		},
    breakpoints: {
      0: {
          slidesPerView: 1.8,
      },
      992: {
          slidesPerView: 2,
          spaceBetweenSlides: 40
      },
      1200: {
          slidesPerView: 3,
          spaceBetweenSlides: 40
      }
    }
	});
	// custom cursor
	var kursorx = new kursor({
		type: 4,
		color: '#1f2937'
	});
  if($(".blogs-detail-view .content p").length){
    $($(".blogs-detail-view .content p")[0]).prepend('<span class="bigword">'+$($(".blogs-detail-view .content p")[0]).text().slice(0,1)+'</span>');
    let clone = $(".blogs-detail-view .content .ads-banner").clone();
    $(".blogs-detail-view .content .ads-banner").remove()
    $($(".blogs-detail-view .content p")[Math.round($(".blogs-detail-view .content>p").length/2)]).after(clone);
  }
  if($(window).width() > 992)
    sal({ });
  else
    $('*').removeAttr('data-sal');

    $(".about-our-strengths .card-col").sort(() => Math.random() - 0.5).each(function(i, obj) {
      $(obj).removeClass("d-none");
  });
});